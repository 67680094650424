@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #333;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:46px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family:'Gruppo', cursive !important;
	font-weight:400;
	color:#000;
}
h2 {
	font-size:36px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:44px;
	font-family: 'Montserrat', sans-serif;
}
h3 {
	font-size:24px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Montserrat', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Montserrat', sans-serif;
}
h6 {
	font-size:16px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Montserrat', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Montserrat', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#49256e;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #414040;
	font-family: 'Montserrat', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#333;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Montserrat', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #333;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #333;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0; opacity:1;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	/*top: 8px;
	left: -15px;*/
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #414040;
	font-size: 14px;
	font-family: 'Montserrat', sans-serif; text-transform:capitalize;
}
.links > li > a:hover {
	background-color: #933E3E;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #414040;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #414040;
}
#nav .nav-panel--dropdown a {
	color: #777;
	font-size: 14px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 14px;
}
#nav .nav-panel--dropdown a:hover {
	color: #414040;
}
.mobnav-trigger.active {
	background-color: #414040;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #777;
}
#nav .nav-item--home .fa-home {
	color: #414040 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #414040;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #414040 !important;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #414040;
	border-right-color: #414040;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #325A76 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #933E3E !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 48px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #325A76;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #933E3E !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #ddd;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #ebebeb;
}
.footer-top-container {
	padding-top: 5px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 0;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999;
}

/*Footer by LM :starts*/
#footer .section.clearer {
     width:100%;
     padding-bottom:0;
     padding-top:0;
}
.footer-top-container .section {
     padding:3px 0;
}
/*Newsletter module*/
/*Style the Newsletter input box*/
#news-letter-input_email {
     width:150px !important;
     height:30px;
}
/*Newsletter button*/
#subscribe-form .button span span {
     line-height:30px;
     height:30px;
     color:#000;
}
#subscribe-form .button span, #subscribe-form .button:hover span{
     background-color:#AACA62;
}
/*Hide the copyright*/
.footer-bottom-container {
     display:none;
}
/*Logo*/
#footer img {
     max-width:300px;
}
#footer .logo-column {
     margin-top:40px;
}
/*Footer links*/
#footer .collapsible.mobile-collapsible .block-title.heading, .newsletter-label-input_email {
     text-transform:Capitalize;
     font-size:16px !important;
     font-weight:700 !important;
     line-height:21px;
     letter-spacing:0;
     margin-bottom:5px;
}
.links-column h6, .links-column a, .newsletter-label-input_email {
     color:#fff;
}
.links-column .fa.fa-linkedin-square {
     font-size:30px;
     padding-top:20px;
}
/*Remove bullets and add the line height*/
#footer .collapsible.mobile-collapsible ul.bullet li {
     background:none;
     padding-left:0;
     line-height:21px;
}
#footer .collapsible.mobile-collapsible ul.bullet li a {
     font-size:16px;
     font-weight:400;
}
/*Reduce the logo size and font size of headings for small devices*/
@media only screen and (min-width:768px) and (max-width:1279px) {
     #footer img {
         max-width:250px;
    }
}
/*Add a bottom border when hovered*/
#footer .collapsible.mobile-collapsible ul.bullet li a:hover {
     border-bottom:2px solid #fff;
}
/*Tablet footer*/
@media only screen and (min-width:768px) and (max-width:959px) {
     #footer .logo-column {
         width:45%;
    }
     #footer .links-column {
         width:50%;
    }
     #footer .subscribe-column {
         width:40%;
    }
}
/*Mobile footer*/
@media only screen and (max-width:767px) {
     .footer-top-container {
         padding-top:10px !important;
         padding-bottom:50px !important;
    }
     #footer .logo-column {
         text-align:center;
         margin-bottom:20px;
    }
     #footer .links-column {
         margin-bottom:20px;
    }
     #footer #subscribe-form .input-box {
         width:154px;
    }
     #footer .newsletter-label-input_email {
         display:block;
         width:100%;
    }
     .collapsible .opener:hover {
         background-color:#fff;
    }
     .collapsible.active .opener:hover {
         background-image:url('https://commercebuild-themes.mysagestore.com/static/Freemen-Nutra/images/infortis/ultra-megamenu/opener-active.png');
    }
     .collapsible .opener:hover {
         background-image:url('https://commercebuild-themes.mysagestore.com/static/Freemen-Nutra/images/infortis/ultra-megamenu/opener.png');
    }
}
@media only screen and (max-width:479px) {
     #footer #subscribe-form .button {
         margin-top:-30px;
    }
}
 /*scroll top icon*/
#scroll-to-top {
     background-color:#96D38D;
}
#scroll-to-top:hover {
     background-color:#325A76;
}
/*Footer by LM ends*/
 
/*LM Header starts**/
/*Sticky header*/
#header {
     position:sticky;
     top:0;
     z-index:50;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
/*Reduce the line-height and margin to align welcome text and user links horizontally*/
.links>li, .links>li>a {
     line-height:2em;
}
p.welcome-msg {
     margin:0;
}
/*Set font color to white for certain data points*/
.desktop-header .fa-shopping-cart, .userSwitch a, .links>li>a{
     color:#fff !important;
}
/*Add Fa icons along with text for user links*/
.module-user-links .links .link-logout::before {
     content:"\f08b";
}
.module-user-links .links .link-admin::before {
     content:"\f013";
}
.module-user-links .links .link-account::before {
     content:"\f007";
}
.module-user-links .links .link-login::before {
     content:"\f090";
}
 .module-user-links .links .link-register::before {
     content:"\f007";
}
.module-user-links .links>li>a:before{
     font-family:fontAwesome;
     margin-right:5px;
}
/*Reduce the font size of welcome text and user links for small width desktops*/
@media only screen and (min-width:960px) and (max-width:1199px) {
     .links>li>a, .header .userCode, .header .userName, .header .userSwitch, .header a, .header p, .header span {
         font-size:12px;
    }
}
/*Change the text Switch Customer to a fa icon for devices less than 1440px*/
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/****Logo****/
#header img {
     max-width:300px;
}
/*Reduce the width of logo module for smaller devices*/
@media only screen and (max-width:1365px) {
     #header img {
         max-width:250px;
    }
}
@media only screen and (max-width:1199px) {
     #header img {
         max-width:200px;
    }
}
/****Search Module*****/
#header #search_mini_form .input-text {
     border:1px solid #325A76;
     border-radius:10px 0px;
}
/*****Category module*****/
#header #nav .nav-item.nav-item--home.level0 {
     display:none;
}
#header .nav-container.module-category-menu .nav {
     width:100%;
     max-width:100%;
}
 #header #nav {
     margin:0;
}
.category-column {
     margin-top:20px;
}
/*Evenly spaced category menu*/
.category-column ul#nav {
     width:100%;
     float:left;
     display:flex;
}
/*For all <li>s*/
.category-column ul#nav > li.level0 {
     float:left;
     margin:auto;
}
/*But except the first and the last <li>*/
.category-column ul#nav > li.level0:nth-child(2) {
     margin-left:0;
}
.category-column ul#nav > li.level0:last-child {
     margin-right:0;
}
/*Add bottom border to the header*/
@media only screen and (min-width:768px) {
#top #header {
     border-bottom:1px solid #325A76;
}
}
/*Mobile Header*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
     .mobile-header .fa-bars.userCode {
         color:#414040 !important;
    }
     .mobile-header .item-welcome-msg {
         margin-left:-2px !important;
    }
     .mobile-header .item-logo .logo{
         float:left;
    }
     .mobile-header .search-column {
         margin-top:10px;
         margin-bottom:10px;
    }
     .no-bkgd-clr {
         overflow:unset;
    }
     .mobile-header .menu-icon.fa-vcard:before {
         content:"\f007";
    }
     .mobile-header .menu-icon.fa-vcard {
         color:#fff;
    }
     /*Scroll the mobile menu if the list of subcategories goes beyond the fold*/
     .mm-panels .sidebar-category ul.show-more-action.accordion {
         overflow-y:auto;
    }
     .mobile-header .fa-bars {
         font-size:22px !important;
    }
     .mobile-header .no-bkgd-clr {
         width:88% !important;
    }
     .mobile-header .quote-column {
         width:6% !important;
         margin-top:15px;
    }
     .mobile-header .quote-link img {
         width:30px !important;
         height:30px !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
/*Remove padding from the sides of the header*/
@media only screen and (max-width:767px) {
     .xm-grid-header .container {
         padding:0 !important;
         width:100% !important;
    }
     .header-top-container .container {
         width:100% !important;
         max-width:100% !important;
    }
     h1 {
         font-size:36px;
    }
}
.no-bkgd-clr .header-top-container{
     background-color:#fff;
}
/*Align the search box in the center*/
.mobile-header .module-search.search-wrapper {
     width:98%;
     margin:0 auto;
}
/*Tablet header*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .mobile-header .no-bkgd-clr .item-menu-icon {
         width:15%;
         margin-top:15px;
    }
     .mobile-header .item-logo {
         width:66%;
    }
     .mobile-header .item-min-cart {
         width:15%;
         margin-top:20px;
    }
     .mobile-header .header .item-left {
         margin-right:0;
    }
     .mobile-header .item-logo .module-logo{
         text-align:center;
    }
     .mobile-header .item-logo .logo{
         float:none;
    }
     .feature-icon-hover .caret {
         border-top-color:#414040;
    }
}
/*Header:ends*/

/*Category pages :start*/
/*Toolbar section alignment*/
.toolbar-section .module-category-misc {
     text-align:center;
}
.pagination-bottom .module-category-misc {
     text-align:right;
}
.pager {
     border:none;
}
/*Color of the selected page according to theme colors*/
.pager .pages li a{
     background-color:#96D38D;
     color:#000;
}
.pager .pages li.current{
     background-color:#325A76;
     color:#fff;
}
.pager .pages li a:hover {
     background-color:#325A76;
}
/*Toolbar section for various devices so it is on one line*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
         ;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
}
/*Align the result count with the rest of the toolbar scetion*/
@media only screen and (min-width:768px) and (max-width:959px) {
     .toolbar-section .module-category-misc .amount-total {
         padding-bottom:2px;
    }
}
/*Category pages:end*/
/*All buttons on the site*/
button.button span{
     background-color:#7EB72C;
     color:#fff;
     border-radius:8px;
}
button.button:hover span {
     background-color:#FB9A10;
     color:#fff;
}
button.button span span {
     padding:0 15px;
     height:35px;
     line-height:35px;
}
.add-cart-wrapper button span span {
     width:120px;
     max-width:140px;
}
/*Buttons on the cart widget - reduce the width and align them in the center*/
#mini-cart .actions button {
     width:80%;
     float:none;
}
#mini-cart .actions.exist-cart {
     text-align:center;
}
/************************** MICS **************************/

/*category pages banner*/
.category-banners .top-banner {
     margin-left:0;
     margin-right:0;
     width:100%;
}
.category-banners .outer {
}
.category-banners .outer .inner {
     position:absolute;
     width:100%;
     height:100%;
     top:0;
     display:flex;
}
.category-banners .inner h2 {
     width:fit-content;
     float:left;
     margin:auto 50px;
}
.category-banners .inner p:not(.empty) {
     width:30%;
     float:left;
     margin:auto 50px;
}
.category-banners .inner p.empty {
     width:10%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .category-banners .inner p:not(.empty) {
         font-size:12px;
         line-height:18px;
    }
}
.category-pages .breadcrumbs .module-category-misc .grid12-0 {
     margin-left:0;
}
.category-pages .page-title h1 {
     padding-top:0;
}
/*Products category*/
.products-category .product-search-container {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.products-category .category-tiles{
     background:url("https://cdn-881a96c5-a77b871b.commercebuild.com/6b4f45c75ee854a2e4c039531e04ae45/contents/ckfinder/images/FreemenNutra_Bug_transparency.png") no-repeat center;
     background-size:contain;
}
.products-category .category-tiles h2 {
     margin-top:70px;
     margin-bottom:70px;
}
.products-category .module-sub-categories img {
     width:65%;
}
.products-category .module-sub-categories-img {
     text-align:center;
}
.products-category .module-sub-categories > div {
     margin-bottom:40px;
}
.products-category .module-sub-categories > div:last-child {
     display:none;
}
/*About us page:starts*/
.about-us .intro .nested-container, .about-us .belief .nested-container, .about-us .know-us .nested-container, .about-us .story .nested-container, .about-us .geography .map .page-content, .geography .locations .page-content{
     margin:0 auto;
}
.about-us .story .nested-container, .about-us .geography .nested-container {
     width:100%;
}
.about-us .belief .grid-container {
     display:grid;
}
@media only screen and (min-width:960px) {
     .about-us .belief .grid-column-wrapper, .about-us .intro p.txt, .about-us .story .timeline {
         width:70%;
         margin:0 auto;
    }
}
@media only screen and (min-width:1600px) {
     .about-us .story .timeline {
         width:50%;
    }
}
.about-us .belief h3, .about-us .belief ul{
     color:#fff;
}
.about-us .belief ul {
     font-weight:500;
}
.about-us cite {
     font-style:italic;
}
.about-us ul {
     list-style:disc;
     margin-left:15px;
}
.about-us .know-us .outer {
     display:flex;
     justify-content:center;
}
.about-us .know-us .outer > div{
     margin:auto 20px;
}
.about-us .know-us .outer > div:nth-child(1){
     width:10%;
     text-align:center;
}
.about-us .know-us .outer > div:nth-child(1) img{
     width:60%;
}
.about-us .story .grid-column-wrapper {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.about-us .story h2 {
     text-align:center;
     color:#7EB72C;
}
.about-us .story .timeline {
     margin:0 auto;
     display:flex;
     padding-top:20px;
}
.about-us .story .timeline > div {
     margin:auto;
}
.about-us .story .timeline > div:nth-child(1) {
     width:10%;
}
.about-us .story .timeline > div:nth-child(2) {
     width:10%;
     border-top:1px solid #7EB72C;
     height:2px;
}
.about-us .story .timeline > div:nth-child(4) {
     width:50%;
}
@media only screen and (max-width:767px) {
     .about-us .story .timeline > div:nth-child(3) h2 {
         font-size:18px;
    }
     .about-us .story .timeline > div:nth-child(4) {
         font-size:14px;
    }
}
.about-us .tea-leaf {
     position:absolute;
     left:0;
     top:-30%;
}
.about-us .tea-leaf img{
     width:20%;
}
.about-us .flower {
     position:absolute;
     right:0;
     top:-10%;
     width:10%;
}
.about-us .flower img {
     float:right;
}
.about-us .beans {
     position:absolute;
     left:0;
     top:40%;
}
.about-us .beans img {
     width:30%;
}
.about-us .stevia-leaf .stevia-leaf{
     position:absolute;
     top:-240px;
     right:0;
}
.about-us .stevia-leaf img {
     width:40%;
     float:right;
}
.about-us .map {
     background-color:#F4F5F7;
}
 .about-us .locations {
     background-color:#7EB72C;
}
 .locations .uk-section-primary.uk-section{
     padding-top:50px;
     padding-bottom:50px;
}
.locations .uk-grid-divider {
     margin-left:-40px;
}
@media (min-width:1200px) {
     .locations .uk-grid-divider {
         margin-left:-80px;
    }
}
@media only screen and (max-width:959px) {
     .flower, .beans, .stevia-leaf, .tea-leaf {
         display:none;
    }
     .know-us, .story, .geography {
         overflow-x:hidden;
    }
}
@media only screen and (max-width:767px) {
     .about-us .know-us .outer {
         display:block;
    }
     .about-us .know-us .outer > div:nth-child(1) {
         width:15%;
         float:left;
         padding-top:30px;
    }
     .about-us .know-us .outer> div:nth-child(2) {
         width:60%;
         float:left 
    }
     .about-us .know-us .outer > div:nth-child(3) {
         text-align:center;
    }
     .about-us .know-us .outer > div:nth-child(1) img {
         width:fit-content;
    }
}
/*About us page:ends*/
/*Premix page:start*/
.premix .heading .nested-container, .premix .applications .nested-container, .premix .products .nested-container {
     margin:0 auto;
     float:none;
}
.premix .categories .grid-column-wrapper {
     margin-left:0;
     margin-right:0;
     width:32.59%;
}
.premix .categories .outer {
     position:relative;
}
.premix .categories h3{
     position:absolute;
     width:100%;
     top:40%;
     text-align:center;
     color:#fff;
     padding:10px 0;
     margin:0;
     background-color:rgba(0,0,0,0.3);
}
.premix .products .grid-column-wrapper:not(.first) {
     border-radius:24px;
     box-shadow:0px 10px 24px -4px rgba(0, 0, 0, 0.25);
}
.premix .products img {
     border-top-left-radius:24px;
     border-top-right-radius:24px;
}
.premix .products .grid-container {
     padding-bottom:30px;
}
.premix .compliance .grid-column-wrapper {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.premix .compliance h2 {
     position:absolute;
     top:15%;
     left:25%;
}
@media only screen and (min-width:1280px) and (max-width:1599px) {
     .premix .compliance h2 {
         left:20%;
    }
}
@media only screen and (max-width:767px) {
     .premix .heading h2 br, .about-us .intro h4 br {
         display:none;
    }
     .premix .applications img {
         width:60%;
    }
     .premix .products .grid-column-wrapper:not(.first) {
         margin-bottom:40px;
    }
     .premix .compliance h2 {
         font-size:14px;
         line-height:14px;
         top:10%;
         left:13%;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .premix .compliance h2 {
         top:12%;
         left:18%;
    }
     .premix .compliance {
         overflow:hidden !important;
    }
}
/*Premix:ends*/
/*Digexin:starts*/
.digexin .logo-image .nested-container, .digexin .logo-image .nested-container, .digexin .info .nested-container, .digexin .brain-axis .nested-container, .digexin .factor .nested-container, .digexin .functions .nested-container, .digexin .formula .nested-container, .digexin .interactive .nested-container, .digexin .benefits .nested-container {
     margin:0 auto;
     float:none;
}
.digexin .axis-image img {
     width:40%;
}
.digexin .results {
     background-color:#003060;
     padding-top:30px;
     padding-bottom:30px;
}
.digexin .biomarkers {
     background-color:#E1C073;
     padding-top:11px;
     padding-bottom:2px;
}
.digexin .factor h3, .digexin .factor p {
     color:#003060;
     text-align:center;
     margin-bottom:20px;
}
.digexin .factor img {
     width:20%;
}
.digexin .functions img {
     width:30%;
}
.digexin .functions h3{
     font-size:20px;
}
.digexin .functions p, .digexin .functions h3 {
     text-align:center;
     color:#003060;
}
.digexin .interactive h2, .digexin .interactive p {
     color:#fff;
}
@media only screen and (min-width:768px) {
     .digexin .interactive .outer {
         display:flex;
    }
     .digexin .interactive .outer .heading {
         margin:auto;
         width:50%;
    }
     .digexin .interactive .outer .txt {
         margin:auto;
         width:60%;
    }
}
.digexin .benefits p {
     text-align:center;
     font-size:13px;
}
.digexin .benefits img {
     width:30%;
}
.digexin .benefits .grid-column-wrapper {
     margin-bottom:30px;
}
.digexin .benefits .logo-img img {
     width:14%
}
.digexin .benefits .logo-img span {
     padding-top:11px;
     display:inline-block;
     font-size:38px;
     padding-left:12px;
     font-weight:700;
     color:#003060;
}
@media only screen and (max-width:767px) {
     .digexin .results p {
         text-align:center;
         margin-right:inherit;
    }
     .digexin .biomarkers h3{
         text-align:center;
         margin-left:inherit;
    }
     .digexin .biomarkers .outer {
         display:flex;
         margin-left:inherit;
    }
     .digexin .biomarkers .outer > div {
         margin:auto;
    }
     .digexin .factor p br {
         display:none;
    }
     .digexin .interactive h2, .digexin .interactive p {
         text-align:center;
    }
     .digexin .benefits .grid12-1 {
         display:none;
    }
     .digexin .benefits .logo-img img {
         width:30% 
    }
     .digexin .benefits .logo-img span {
         padding-top:5px;
         font-size:30px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .digexin .benefits .logo-img img {
         width:22% 
    }
}
 /*Ends*/
/*Contact Us:starts*/
@media only screen and (min-width:1280px) {
     .contact-us .form-section {
         padding-top:50px;
    }
}
.contact-us .logo-section .outer > div{
     text-align:center;
}
.contact-us .logo-section .outer > p {
     padding-top:70px;
     padding-bottom:70px;
}
.contact-us .logo-section div > img {
     width:40%;
}
.contact-us .fieldset {
     padding:0;
}
@media only screen and (min-width:1280px) {
     .contact-us .fieldset {
         width:80%;
    }
}
.contact-us .module_custom_form li.fields:nth-child(-n+2) {
     width:47%;
     float:left;
}
.contact-us .module_custom_form li.fields:nth-child(1) {
     margin-right:20px;
}
.contact-us .module_custom_form li.fields .input-text, .contact-us .module_custom_form li.fields textarea{
     width:100%;
}
 .contact-us button, .contact-us button span span {
     width:80%;
}
@media only screen and (max-width:1279px) {
     .contact-us button, .contact-us button span span {
         width:95%;
    }
}
 .contact-us button span {
     width:100%;
}
.contact-us .locations {
     background-color:#345F79;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .contact-us .logo-section .outer > p {
         padding-top:100px;
         padding-bottom:100px;
    }
}
/*Contact Us:ends*/
/*Promotion banner on Homepage and Products category*/
/*Promotions Slider*/
 .promotions {
     background-color:#FFF0CA;
     margin-bottom:0;
}
.promotions .outer {
     display:flex;
     width:50%;
     margin:0 auto;
}
.promotions .outer > div {
     margin:auto;
}
.promotions .outer > div:nth-child(1) {
     width:20%;
}
.promotions .beans-img {
     position:absolute;
     top:-50%;
     right:0;
}
.promotions .beans-img img{
     width:30%;
     float:right;
}
@media only screen and (max-width:767px) {
     .promotions .outer {
         width:initial;
         padding-top:20px;
         padding-bottom:20px;
    }
     .promotions .outer > div {
         margin:auto 10px;
    }
     .promotions .beans-img {
         display:none;
    }
     .promotions h2 {
         font-size:24px;
    }
     .promotions p {
         font-size:13px;
         margin-bottom:0;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     /*Promotions Slider*/
     .promotions .outer {
         width:initial;
    }
     .promotions .outer > div {
         margin:auto 30px;
    }
     .promotions .beans-img {
         top:0;
    }
     .promotions .beans-img img{
         width:10%;
    }
     .promotions p {
         margin-bottom:0;
    }
}
/*Promotion slider:ends*/
/*For Quality & Standards*/
.qns .intro .nested-container, .qns .third-party .nested-container {
     margin:0 auto;
}
.qns .in-house .nested-container{
     width:100%;
}
.qns .in-house {
     background-image:url(https://cdn-881a96c5-a77b871b.commercebuild.com/6b4f45c75ee854a2e4c039531e04ae45/contents/ckfinder/images/Quality-and-Standards/Blue-Banner-Quality.jpg);
     background-repeat:no-repeat;
     background-position:100%;
     background-size:cover;
     display:flow-root;
     box-sizing:border-box;
     padding:0 !important;
}
.qns .in-house .gingko-leaf {
     width:10%;
     float:left;
     position:absolute;
     top:-30%;
}
.qns .in-house .txt {
     width:40%;
     margin-left:10%;
     padding-top:70px;
     padding-bottom:80px;
}
.qns .third-party p img {
     width:60%;
}
.qns .audit {
     background-image:url(https://cdn-881a96c5-a77b871b.commercebuild.com/6b4f45c75ee854a2e4c039531e04ae45/contents/ckfinder/images/Quality-and-Standards/White-Banner-2-Quality.jpg);
     background-repeat:no-repeat;
     background-position:100%;
     background-size:cover;
     display:flow-root;
     box-sizing:border-box;
     padding:0 !important;
}
.qns .audit .echinacea-leaf {
     width:10%;
     float:right;
     position:absolute;
     top:-40%;
     right:0;
}
.qns .audit .txt {
     width:40%;
     margin-left:10%;
     padding-top:70px;
     padding-bottom:80px;
}
@media only screen and (max-width:767px) {
     .qns .intro p br, .qns .third-party p br {
         display:none;
    }
     .qns .in-house, .qns .audit{
         background-size:100%;
         background-position:100% 0;
    }
     .qns .in-house .hide-above-768 .page-content, .qns .audit .hide-above-768 .page-content {
         padding-top:15%;
    }
     .qns .in-house .hide-above-768 .page-content h3, .qns .audit .hide-above-768 .page-content h3 {
         padding-bottom:15%;
    }
     .qns .third-party p img {
         width:inherit;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .qns .in-house, .qns .audit {
         background-position:75% 0;
    }
     .qns .in-house .gingko-leaf, .qns .audit .echinacea-leaf {
         top:inherit;
    }
     .qns .in-house .txt, .qns .audit .txt {
         width:50%;
    }
     .qns .third-party p img {
         width:80%;
    }
}
/*QnS:ends*/
/*Intelligence:starts*/
@media only screen and (min-width:1600px) {
     .intelligence .form-section {
         padding-top:50px;
    }
     .intelligence .form-section .nested-container .grid-container {
         padding-left:10%;
    }
}
.intelligence .form-section .nested-container {
     width:60%;
}
.intelligence .fieldset {
     padding:0;
}
@media only screen and (min-width:1280px) {
     .intelligence .fieldset, .intelligence .form-section .nested-container {
         width:80%;
    }
}
.intelligence .module_custom_form li.fields:nth-child(-n+2) {
     width:47%;
     float:left;
}
.intelligence .module_custom_form li.fields:nth-child(1) {
     margin-right:20px;
}
.intelligence .module_custom_form li.fields .input-text{
     width:100%;
}
 .intelligence button, .intelligence button span span {
     width:80%;
}
@media only screen and (max-width:1279px) {
     .intelligence button, .intelligence button span span {
         width:95%;
    }
}
 .intelligence button span {
     width:100%;
}

@media only screen and (max-width:1199px) {
     .intelligence .form-section br {
         display:none;
    }
     .intelligence .img-section {
         width:50%;
    }
     .intelligence .form-section {
         width:48%;
    }
     .intelligence .form-section .nested-container {
         width:80%;
    }
}
@media only screen and (max-width:959px) {
     .intelligence .form-section .nested-container {
         width:100%;
    }
}
@media only screen and (max-width:767px) {
     .intelligence .form-section .nested-container {
         width:90%;
    }
}
/*Intelligence:end*/
/*Careers:starts*/
@media only screen and (min-width:1600px) {
     .careers .resume {
         padding-top:80px;
    }
     .careers .resume .nested-container .grid-container {
         padding-left:20%;
    }
}
@media only screen and (min-width:1200px) and (max-width:1599px) {
     .careers .resume {
         padding-top:50px;
    }
     .careers .resume .nested-container .grid-container {
         padding-left:20%;
    }
}
.careers .resume .nested-container {
     width:80%;
}
@media only screen and (max-width:1279px) {
     .careers .resume br {
         display:none;
    }
     .careers .img-section {
         width:50%;
    }
     .careers .resume {
         width:50%;
    }
}
@media only screen and (max-width:959px) {
     .careers .resume .nested-container {
         width:100%;
    }
}
@media only screen and (max-width:767px) {
     .careers .resume .nested-container {
         width:90%;
    }
}
.careers .linkedin .nested-container {
     margin:0 auto;
}
.careers .outer .content {
     float:left;
     width:50%;
}
.careers .outer .leaf-img {
     float:right;
     width:30%;
}
.careers .outer .leaf-img img{
     float:right;
     width:70%;
}
.careers .linkedin .content {
     display:flex;
     justify-content:center;
     padding-top:4%;
     margin-left:15%;
}
.careers .linkedin .content > div{
     margin:auto 20px;
}
.careers .linkedin .content > div:nth-child(1){
     width:12%;
     text-align:center;
}
.careers .leaf-img img {
     margin-top:-50px;
}
@media only screen and (max-width:1535px) {
     .careers .linkedin .content {
         padding-top:3%;
         margin-left:15%;
    }
}
@media only screen and (max-width:1279px) {
     .careers .linkedin .content {
         padding-top:2%;
         margin-left:10%;
    }
}
@media only screen and (max-width:767px) {
     .careers .linkedin .content {
         display:block;
         margin-left:inherit;
         width:100%;
    }
     .careers .linkedin .content > div:nth-child(1) {
         width:15%;
         float:left;
         padding-top:30px;
    }
     .careers .linkedin .content> div:nth-child(2) {
         width:60%;
         float:left 
    }
     .careers .linkedin .content > div:nth-child(3) {
         text-align:center;
    }
     .careers .linkedin .content > div:nth-child(1) img {
         width:fit-content;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .careers .outer .content {
         width:80%;
         margin-left:inherit;
    }
}
/*Careers:ends*/

